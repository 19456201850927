import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

interface Props {
  children?: any;
  /**
   * The width divided by the height. This ratio can be passed in
   * using JavaScript division syntax. So, to get a 16:9 ratio,
   * simply pass `ratio={16/9}`.
   */
  ratio: number;
}
export const AspectRatio = ({ children, ratio }: Props) => {
  const { aspectFixP1, aspectFixP2 } = useStyles(ratio)();
  return (
    <div className={aspectFixP1}>
      <div className={aspectFixP2}>{children}</div>
    </div>
  );
};

const useStyles = (ratio: number) =>
  makeStyles({
    aspectFixP1: {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: `${100.0 / ratio}%`
    },
    aspectFixP2: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  });
