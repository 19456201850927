import NoteAdd from '@material-ui/icons/NoteAdd';
import ViewComfy from '@material-ui/icons/ViewComfy';
import ViewModule from '@material-ui/icons/ViewModule';

import Save from '@material-ui/icons/Save';
import Open from '@material-ui/icons/OpenInBrowser';
import React, { useState } from 'react';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';

import { plate96, plate384, PlateType } from './PlateType';
import { OpenTextFile } from './OpenTextFile';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles
} from '@material-ui/core';
import { mdiExport, mdiImport } from '@mdi/js';
import Icon from '@mdi/react';

interface Props {
  save: () => void;
  load: (content: string) => void;
  newPlate: (plateType: PlateType) => void;
  print: () => void;
  copyToClipboard: () => void;
  exportToCsv: () => void;
  importFromClipboard: () => void;
  importFromFile: () => void;
  children: (openDrawer: () => void) => React.ReactNode;
}

export const DrawerMenu: React.FC<Props> = props => {
  const new96 = () => props.newPlate(plate96);
  const new384 = () => props.newPlate(plate384);
  const { icon } = useStyles();

  const closeMenuAfterAction = (action: () => void) => () => {
    action();
    setShowDrawer(false);
  };

  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      {props.children(() => setShowDrawer(true))}
      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <List style={{ width: 300 }}>
          <OpenTextFile
            loadTextContent={content => closeMenuAfterAction(() => props.load(content))}
            dataCy="file-open">
            {action => (
              <ListItem button key={'Open'} onClick={action}>
                <ListItemIcon>{<Open />}</ListItemIcon>
                <ListItemText primary={'Open'} />
              </ListItem>
            )}
          </OpenTextFile>
          <ListItem button key={'Save'} onClick={closeMenuAfterAction(props.save)}>
            <ListItemIcon>{<Save />}</ListItemIcon>
            <ListItemText primary={'Save'} />
          </ListItem>

          <Divider />
          <ListItem button key={'New'}>
            <ListItemIcon>{<NoteAdd />}</ListItemIcon>
            <ListItemText primary={'New'} />
          </ListItem>

          <ListItem
            button
            key={'New96'}
            onClick={closeMenuAfterAction(new96)}
            style={{ paddingLeft: '40px' }}>
            <ListItemIcon>{<ViewModule />}</ListItemIcon>
            <ListItemText primary={'96 Well Plate'} />
          </ListItem>
          <ListItem
            button
            key={'New384'}
            onClick={closeMenuAfterAction(new384)}
            style={{ paddingLeft: '40px' }}>
            <ListItemIcon>{<ViewComfy />}</ListItemIcon>
            <ListItemText primary={'384 Well Plate'} />
          </ListItem>

          <Divider />
          <ListItem button key={'ImportHeader'}>
            <ListItemIcon>
              <Icon path={mdiImport} size={1} className={icon} />
            </ListItemIcon>
            <ListItemText primary={'Import'} />
          </ListItem>
          <ListItem
            button
            key={'ImportFromFile'}
            onClick={closeMenuAfterAction(props.importFromFile)}
            style={{ paddingLeft: '40px' }}>
            <ListItemIcon>{<InsertDriveFileOutlined />}</ListItemIcon>
            <ListItemText primary={'From CSV File'} />
          </ListItem>
          <ListItem
            button
            key={'ImportFromClipboard'}
            onClick={closeMenuAfterAction(props.importFromClipboard)}
            style={{ paddingLeft: '40px' }}>
            <ListItemIcon>{<AssignmentOutlined />}</ListItemIcon>
            <ListItemText primary={'From Clipboard'} />
          </ListItem>
          <Divider />
          <ListItem button key={'ExportHeader'}>
            <ListItemIcon color="primary">
              <Icon path={mdiExport} size={1} className={icon} />
            </ListItemIcon>
            <ListItemText primary={'Export'} />
          </ListItem>
          <ListItem
            button
            key={'ExportToCSVFile'}
            onClick={closeMenuAfterAction(props.exportToCsv)}
            style={{ paddingLeft: '40px' }}>
            <ListItemIcon>{<InsertDriveFileOutlined />}</ListItemIcon>
            <ListItemText primary={'To CSV File'} />
          </ListItem>
          <ListItem
            button
            key={'Copy To Clipboard'}
            onClick={closeMenuAfterAction(props.copyToClipboard)}
            style={{ paddingLeft: '40px' }}>
            <ListItemIcon>{<AssignmentOutlined />}</ListItemIcon>
            <ListItemText primary={'To Clipboard'} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    fill: theme.palette.text.secondary
  }
}));
