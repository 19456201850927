import { createMuiTheme, CssBaseline, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React, { useState } from 'react';
import { Feature, FeatureToggleContext } from './FeatureToggles';
import { Plateau } from './Plateau';

const App: React.FC<{}> = () => {
  const [prefersDarkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => setDarkMode(!prefersDarkMode);

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: {
            light: '#e26d6d',
            main: '#DB4949',
            dark: '#993333'
          },
          secondary: {
            light: '#e26d6d',
            main: '#DB4949',
            dark: '#993333'
          }
        }
      }),
    [prefersDarkMode]
  );

  const [features, setFeatures] = useState([] as Feature[]);
  const toggleFeatures = () => {
    if (features.length === 0) alert('Warning: Unstable features enabled');
    setFeatures(features.length === 0 ? ['PlateToClipboard'] : []);
  };

  const { footer } = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <FeatureToggleContext.Provider value={features}>
        <CssBaseline />
        <Plateau toggleDarkMode={toggleDarkMode} toggleFeatures={toggleFeatures} />
        <div className={footer}>Generated by Plateau. https://plateau.firefinch.io/</div>
      </FeatureToggleContext.Provider>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(theme => ({
  footer: {
    '@media screen': {
      display: 'none'
    },
    '@media print': {
      position: 'fixed',
      bottom: 0
    }
  }
}));

export default App;
