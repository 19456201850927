import React from 'react';

import { FileUploadButton } from './FileUploadButton';

interface Props {
  loadTextContent: (content: string) => void;
  children: (action: () => void) => React.ReactNode;
  dataCy?: string;
}

export const OpenTextFile: React.FC<Props> = ({ loadTextContent, children, dataCy }) => {
  const fileReader = new FileReader();

  const loadFromCsv = () => {
    loadTextContent(fileReader.result! as string);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.validity.valid) {
      // tslint:disable-next-line:no-console
      console.log('File target invalid');
      return;
    }

    fileReader.onloadend = loadFromCsv;
    fileReader.readAsText(event.target.files![0]);
  };

  return (
    <FileUploadButton onChange={onChange} buttonText="" dataCy={dataCy}>
      {uploadCsv => children(uploadCsv)}
    </FileUploadButton>
  );
};
