import fileDownload from 'js-file-download';
import { parse, unparse } from 'papaparse';
import React from 'react';
import { PlateType } from './PlateType';
import { getColumnFromWellId, getRowFromWellId, Well } from './Well';
import { ImportDialogContainer } from './ImportDialogContainer';

interface ToolbarProps {
  save: () => void;
  load: (content: string) => void;
  newPlate: (plateType: PlateType) => void;
  print: () => void;
  copyToClipboard: () => void;
  exportToCsv: () => void;
  importFromClipboard: () => void;
  importFromFile: () => void;
}

interface Props {
  newPlate: (plateType: PlateType) => void;
  wells: Well[];
  setAllWells: (values: Partial<Well>[]) => void;
  setMetadata: (metadata: any) => void;
  metadata: any;
  children: (toolbarProps: ToolbarProps) => React.ReactNode;
  plateType: PlateType;
}

const tableSeparator = '\n\n';

export const ToolbarContainer: React.FC<Props> = props => {
  const save = () => {
    const wellsCsv = unparse(props.wells);

    const kvps = Object.keys(props.metadata).map((key, i) => ({ key, value: props.metadata[key] }));
    const metadataCsv = unparse(kvps);

    fileDownload(`${metadataCsv}${tableSeparator}${wellsCsv}`, 'PlateauSave.csv');
  };

  const load = (content: string) => {
    const [metadataCsv, wellCsv] = content.replace(/\r/g,'').split(/\n\s*\n/);

    if (metadataCsv == null || wellCsv == null) {
      alert('Invalid file format');
      return;
    }

    const metadataKvp = parse(metadataCsv, { header: true }).data as {
      key: string;
      value: string;
    }[];
    const metadata = {} as any;
    metadataKvp.forEach(kvp => {
      metadata[kvp.key] = kvp.value;
    });

    props.setMetadata(metadata);

    const wells = parse(wellCsv, { header: true });
    props.setAllWells(wells.data);
  };

  const newPlate = (plateType: PlateType) => {
    const newMetadata = {} as any;
    Object.keys(props.metadata).forEach(key => {
      newMetadata[key] = '';
    });
    newMetadata.date = new Date();

    props.setMetadata(newMetadata);
    props.newPlate(plateType);
  };
  const print = () => window.print();

  const copyToClipboard = () => {
    const outputObjs = props.wells.map(a => ({
      wellId: a.wellId,
      type: a.type,
      name: a.name,
      ref: a.ref,
      value: a.value,
      notes: a.notes
    }));
    const csvString = unparse(outputObjs, { header: true, skipEmptyLines: true, delimiter: '\t' });
    navigator.clipboard.writeText(csvString);
  };

  const exportToCsv = () => {
    const csv = unparse(
      props.wells.map(ms => ({
        WellId: ms.wellId,
        Type: ms.type,
        Name: ms.name,
        Ref: ms.ref,
        Value: ms.value,
        Notes: ms.notes
      }))
    );

    fileDownload(csv, 'PlateauExport.csv');
  };

  return (
    <ImportDialogContainer
      updateSelection={props.setAllWells}
      plateType={props.plateType}
      importType="file">
      {(importFromFile: any) => (
        <ImportDialogContainer
          updateSelection={props.setAllWells}
          plateType={props.plateType}
          importType="clipboard">
          {(importFromClipboard: any) =>
            props.children({
              save,
              load,
              print,
              newPlate,
              copyToClipboard,
              exportToCsv,
              importFromClipboard,
              importFromFile
            })
          }
        </ImportDialogContainer>
      )}
    </ImportDialogContainer>
  );
};

export const loadFromCsvString = (csvData: string): Well[] => {
  const csvParse = parse(csvData, { header: true });

  // TODO: handle bad data

  const wells: Well[] = csvParse.data.map(a => ({
    x: getColumnFromWellId(a.WellId),
    y: getRowFromWellId(a.WellId),
    wellId: a.WellId,
    type: a.Type,
    name: a.Name,
    ref: a.Ref,
    description: a.Description,
    value: a.Value,
    notes: a.Notes
  }));

  return wells;
};
