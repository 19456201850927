import {
  TextField,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { parse } from 'papaparse';

const useStyles = makeStyles({
  dialogPaper: {
      minHeight: '70vh',
      maxHeight: '70vh'
  }
});

export const PasteImportDialog: React.SFC<Props> = props => {

  const classes = useStyles();

  const [text, setText] = useState('');
  const [error, setError] = useState(false);

  const onSubmit = () => {
    const sourceData = parse(text, { skipEmptyLines: true }).data;

    if (sourceData.length === 0) {
      setError(true);
    }
    else {
      props.onSubmit(sourceData);
      
    }
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={props.show} disableBackdropClick classes={{paper: classes.dialogPaper}}>
      <DialogTitle id="confirmation-dialog-title">Paste Text</DialogTitle>
      <DialogContent dividers>
          <DialogContentText>Paste your CSV text into this box</DialogContentText>
          <TextField error={error} helperText={error ? 'Please input only CSV formatted data' : null} fullWidth multiline variant="outlined" rows="22" onChange={(event) => setText(event.target.value)}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" data-cy="import-confirm">
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  show: boolean;
  onSubmit: (sourceData: any[]) => void;
  onClose: () => void;
}
