export interface Well {
  x: number;
  y: number;
  wellId: string;
  ref: string;
  type: string;
  name: string;
  value: string;
  notes: string;
}

export const commonWellTypes = ['Calibration Standard', 'Reference Standard', 'Control', 'Samples'];

// TODO: put some tests around these functions
export const convertWellToWellId = (x: number, y: number) => getWellLetter(y) + (x + 1);

export const getWellLetter = (y: number) => String.fromCharCode(y + 65);

export const getColumnFromWellId = (wellId: string) => Number(wellId.substr(1)) - 1;
export const getRowFromWellId = (wellId: string) => wellId.charCodeAt(0) - 65;

export const parseWellId = (wellId: string) => {
  const column = getColumnFromWellId(wellId);
  const row = getRowFromWellId(wellId);
  if (row > 8) return undefined;
  return convertWellToWellId(column, row);
};
