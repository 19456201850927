import { Button } from '@material-ui/core';
import * as React from 'react';

export class FileUploadButton extends React.Component<Props> {
  // @ts-ignore
  private fileUpload: HTMLInputElement;
  uploadClick = () => this.fileUpload.click();

  render() {
    return (
      <>
        <input
          accept="*"
          type="file"
          data-cy={this.props.dataCy ? this.props.dataCy : ''}
          required
          hidden
          onChange={event => {
            this.props.onChange(event);
            event.target.value = '';
          }}
          ref={fileUpload => {
            this.fileUpload = fileUpload!;
          }}
        />
        {this.props.children === undefined ? (
          <Button onClick={this.uploadClick} color={'primary'} component="span">
            {this.props.buttonText}
          </Button>
        ) : (
          this.props.children(this.uploadClick)
        )}
      </>
    );
  }
}

export interface Props {
  buttonText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: (action: () => void) => React.ReactNode;
  dataCy?: string;
}
