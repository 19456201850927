import React from 'react';
import Joyride, { CallBackProps, StoreHelpers, Step, STATUS } from 'react-joyride';

interface Props {
  children: (reset: () => void) => React.ReactNode;
}

const tutorialFinishedStatus: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

export const JoyRide: React.FC<Props> = props => {
  const [joyHelper, setJoyHelper] = React.useState(undefined as StoreHelpers | undefined);

  const joyrideStorageKey = 'tutorialCompleted';
  const joyrideCallback = (a: CallBackProps) => {
    if (tutorialFinishedStatus.includes(a.status)) {
      window.localStorage.setItem(joyrideStorageKey, 'true');
    }
  };

  const runJoyride = window.localStorage.getItem(joyrideStorageKey) == null;

  const tutorial = (
    <Joyride
      steps={steps}
      callback={joyrideCallback}
      run={runJoyride}
      continuous
      scrollToFirstStep
      showProgress
      disableOverlayClose
      showSkipButton
      getHelpers={(helpers: StoreHelpers) => setJoyHelper(helpers)}
      styles={{
        options: {
          primaryColor: '#DB4949',
          zIndex: 10000
        }
      }}
    />
  );

  const reopenTutorial = () => {
    if (!joyHelper) return;

    if (joyHelper.info().status === STATUS.RUNNING) {
      joyHelper.open();
    } else {
      joyHelper.reset(true);
    }
  };

  return (
    <>
      {props.children(reopenTutorial)}
      {tutorial}
    </>
  );
};

export enum JoyRideSteps {
  massEditButton = 'massEditButton',
  plateMetadata = 'plateMetadata',
  plateSelection = 'plateSelection',
  sampleTableEdit = 'sampleTableEdit',
  import = 'import',
  export = 'export',
  sendFeedback = 'sendFeedback'
}

const steps: Step[] = [
  {
    content: (
      <>
        <img src="/logo192.png" alt="logo" />
        <h2>Welcome!</h2>
        <p>Plateau is an app designed to simplify creating and updating your plate layouts</p>
      </>
    ),
    placement: 'center' as 'center',
    target: 'body'
  }, // TODO: fix highlight
  // appears to be a browser specific problem; holding off on fixing until the joyride tutorial is completed
  { target: `#${JoyRideSteps.plateMetadata}`, content: 'Enter information about the plate here.' },
  {
    target: `#${JoyRideSteps.plateSelection}`,
    content:
      'Selecting wells on the plate below filters the table on the right. \n\nHovering the mouse cursor over a well shows a tooltip with the well information.'
  },
  {
    // TODO: fix highlight
    target: `#${JoyRideSteps.massEditButton}`,
    content: 'Information for selected wells can be mass updated by clicking the edit button.'
  },
  {
    target: `#${JoyRideSteps.sampleTableEdit}`,
    content: 'Well details can be directly modified by clicking and typing into this table.'
  },
  // { TODO:
  //   target: `#${JoyRideSteps.save}`,
  //   content: 'You can save your current plate locally.'
  // },
  // {
  //   target: `#${JoyRideSteps.open}`,
  //   content: 'You can open a plate you have previously saved.'
  // },
  // {
  //   target: `#${JoyRideSteps.new}`,
  //   content: 'Gives you an empty plate.'
  // },
  {
    target: `#${JoyRideSteps.import}`,
    content: 'Data can be imported from a *.csv file or from the clipboard.'
  },
  {
    target: `#${JoyRideSteps.export}`,
    content: 'Data can be exported to an external application via the clipboard or to a *.csv file.'
  },
  {
    target: `#${JoyRideSteps.sendFeedback}`,
    content:
      "We'd love to here back from you! Click here to send an email to us with any suggested features or improvements."
  }

  // {
  //   target: '',
  //   content:
  //     'Consecutive well numbering can be generated through the mass update mechanism. A starting number and number of replicates can be set to modify how this is applied.'
  // },

  // {
  //   target: '#copy-sample-table',
  //   content: 'Copy the sample table to clipboard, allows pasting into excel'
  // },
  // { target: '#export-csv', content: 'Export the table to a csv file' },
  // {
  //   target: '#table-well-order',
  //   content: 'Set the table ordering as row or column first ordering'
  // },
  // {
  //   target: '#import-button',
  //   content: 'Import from either a csv file or direct from clipboard, which opens the import wizard'
  // },

  //   {
  //   target: '#importTableHead',
  //   content:
  //   'select which columns from the import file to are assigned to which columns in Plateau, select which columns you want to import'
  //   },
  //   { target: '#headerSelect', content: 'select if your file has headers' },
  //   {
  //   target: '#wellOrdering',
  //   content:
  //   "if the import data doesn't contain well ids, select the correct input row/column orientation, and check that the generated well IDs are correctly aligned with the input data"
  //   }
];
