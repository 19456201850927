import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';

interface Props {
  children: (
    openMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => React.ReactNode;
  copyToClipboard: () => void;
  exportToCsv: () => void;
}

export const ExportMenu: React.FC<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const copyToClipboard = () => {
    handleClose();
    props.copyToClipboard();
  };

  const exportToCsv = () => {
    handleClose();
    props.exportToCsv();
  };

  return (
    <>
      {props.children(handleClick)}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={copyToClipboard}>Copy To Clipboard</MenuItem>
        <MenuItem id="export-csv" onClick={exportToCsv}>
          Export To CSV File
        </MenuItem>
      </Menu>
    </>
  );
};
