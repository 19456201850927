import { Grid, Box, makeStyles } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Camera';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import html2canvas from 'html2canvas';
import React, { createRef, useCallback, useMemo, useReducer, useState } from 'react';

import { MyButton } from './MyButton';
import { generateWellSeed, plate96, PlateType, plate384 } from './PlateType';
import { PlateView } from './PlateView';
import { TableMetadata, Metadata } from './TableMetadata';
import { TableView } from './TableView';
import { Well } from './Well';
import { ImportDialogContainer } from './ImportDialogContainer';
import { JoyRideSteps } from './JoyRide';
import { SelectionEditor } from './SelectionEditor';
import { MyAppBar } from './AppBar';
import { ToolbarContainer } from './ToolbarContainer';

// TODO: handle corrupt data in localstorage
const wellCache = localStorage.getItem('wells');
const metadataCache = localStorage.getItem('metadata');
const wellDataCache = wellCache ? JSON.parse(wellCache) : undefined;
interface Props {
  toggleFeatures: () => void;
  toggleDarkMode: () => void;
}

export const Plateau: React.FC<Props> = props => {
  const [wells, setAllWells] = useReducer(wellReducer, (wellDataCache as Well[]) || initWells);
  const [selectedWellIds, setSelectedWellIds] = useState([] as string[]);
  const [hoverWell, setHoverWell] = useState(undefined as string | undefined);
  const [metadata, setMetadata] = useReducer(
    metadataReducer,
    metadataCache ? JSON.parse(metadataCache) : {}
  );

  const handleSelectionFinish = (selectedItems: any[]) =>
    setSelectedWellIds(selectedItems.map(a => a.props.well.wellId));

  const updateWellsAndClearSelection = (values: Partial<Well>[]) => {
    setAllWells({ wellUpdates: values, type: 'UPDATE' });
    clearSelection();
  };

  const onTableEdit = useCallback(
    (wellId: string, fieldName: string, value: string) =>
      setAllWells({
        wellUpdates: [{ wellId, [fieldName]: value }],
        type: 'UPDATE'
      }),
    []
  );

  const clearSelectionRef: any = createRef();
  const clearSelection = () => {
    if (clearSelectionRef.current == null) return;
    clearSelectionRef.current.clearSelection();
  };

  const noneSelected = selectedWellIds.length === 0;
  const values = wells.map(a => Number(a.value)).filter(a => !isNaN(a));
  const valueMin = Math.min(...values, 0);
  const valueMax = Math.max(...values, 1);
  const conditionalFormatting = useMemo(() => ({ valueMin, valueMax }), [valueMin, valueMax]);

  const newPlate = (plateType: PlateType) => {
    setAllWells({ plateType, type: 'REPLACE' });
  };

  const metablob = (
    <Grid container direction="column" id={JoyRideSteps.plateMetadata} style={{ padding: '0px' }}>
      <TableMetadata metadata={metadata} setMetadata={setMetadata} />
    </Grid>
  );

  const snapshot = () => {
    // @ts-ignore
    html2canvas(document.querySelector('#plate')).then(canvas => {
      document.body.appendChild(canvas);
      canvas.toBlob((blob: any) => {
        const tab = window.open();
        tab!.document.body.appendChild(canvas);
      });
    });
  };

  const plateBlob = (clearSelectionReference: string) => (
    <Grid container direction="row" justify="flex-end">
      <Grid item style={{ height: '100%', width: '100%' }} xs={12}>
        <PlateView
          wells={wells}
          conditionalFormatting={conditionalFormatting}
          handleSelectionFinish={handleSelectionFinish}
          clearSelectionRef={clearSelectionReference}
          setHoverWell={setHoverWell}
          hoverWell={hoverWell}
        />
      </Grid>
      <>
        <Grid item>
          <MyButton tooltip="Snapshot Plate" feature="PlateToClipboard" action={snapshot}>
            <CameraIcon />
          </MyButton>
        </Grid>
        <Grid item id={JoyRideSteps.massEditButton}>
          <SelectionEditor
            wells={wells.filter(a => noneSelected || selectedWellIds.includes(a.wellId))}
            updateSelection={updateWellsAndClearSelection}>
            {openDialog => (
              <MyButton tooltip="Edit Selected Wells" action={openDialog} disabled={noneSelected}>
                <EditIcon />
              </MyButton>
            )}
          </SelectionEditor>
        </Grid>
        <Grid item>
          <MyButton tooltip="Clear Selection" action={clearSelection} disabled={noneSelected}>
            <ClearIcon />
          </MyButton>
        </Grid>
      </>
    </Grid>
  );

  // TODO: this should be coming from somewhere else (also check for other usages)
  const plateType = wells.length === 384 ? plate384 : plate96;

  const { leftSideGridItems, root } = useStyles();

  const mainBody = (clearSelectionReference: string) => (
    <div className={root}>
      <>
        <Box displayPrint="none">
          <ToolbarContainer
            wells={wells}
            newPlate={newPlate}
            metadata={metadata}
            setMetadata={setMetadata}
            setAllWells={updateWellsAndClearSelection}
            plateType={plateType}>
            {toolbarProps => (
              <MyAppBar
                toggleFeatures={props.toggleFeatures}
                toggleDarkMode={props.toggleDarkMode}
                {...toolbarProps}
              />
            )}
          </ToolbarContainer>
        </Box>

        <ImportDialogContainer
          importType="clipboard"
          updateSelection={updateWellsAndClearSelection}
          plateType={plateType}>
          {showImportDialog => (
            <Grid
              container
              direction="row"
              style={{ height: 'calc(100% - 80px)', width: '100%' }}
              onPaste={showImportDialog}>
              <Grid item xs={12} md={6} lg={4}>
                <Grid container direction="column">
                  <Grid
                    item
                    xs={12}
                    children={metablob}
                    style={{ width: '100%' }}
                    className={leftSideGridItems}
                  />
                  <Grid
                    item
                    xs={12}
                    children={plateBlob(clearSelectionReference)}
                    style={{ height: '100%', width: '100%' }}
                    className={leftSideGridItems}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                id={JoyRideSteps.sampleTableEdit}
                xs={12}
                md={6}
                lg={8}
                style={{ maxHeight: '100%' }}>
                <TableView
                  conditionalFormatting={conditionalFormatting}
                  setWellField={onTableEdit}
                  hoverWell={hoverWell}
                  setHoverWell={setHoverWell}
                  wells={wells.filter(a => noneSelected || selectedWellIds.includes(a.wellId))}
                />
              </Grid>
            </Grid>
          )}
        </ImportDialogContainer>
      </>
    </div>
  );

  const isPrint = false;

  return <div style={{ height: isPrint ? 'auto' : '100%' }}>{mainBody(clearSelectionRef)}</div>;
};

const initWells = generateWellSeed(plate96); // loadFromCsvString (seedPlate);

type WellUpdateAction =
  | { type: 'UPDATE'; wellUpdates: Partial<Well>[] }
  | { type: 'REPLACE'; plateType: PlateType };

const wellReducer = (prevWells: Well[], action: WellUpdateAction): Well[] => {
  let newWells: Well[] = [];
  switch (action.type) {
    case 'REPLACE':
      newWells = generateWellSeed(action.plateType);
      break;
    case 'UPDATE':
      newWells = updateWells(action.wellUpdates, prevWells);
      break;
  }

  localStorage.setItem('wells', JSON.stringify(newWells));
  return newWells;
};

const updateWells = (wellUpdates: Partial<Well>[], wells: Well[]) => {
  return wells.map(a => {
    const wellToUpdate = wellUpdates.find(b => b.wellId === a.wellId);
    if (wellToUpdate == null) return a;

    const newWell = { ...a };
    if (wellToUpdate.type != null) newWell.type = wellToUpdate.type;
    if (wellToUpdate.name != null) newWell.name = wellToUpdate.name;
    if (wellToUpdate.notes != null) newWell.notes = wellToUpdate.notes;
    if (wellToUpdate.value != null) newWell.value = wellToUpdate.value;
    if (wellToUpdate.ref != null) newWell.ref = wellToUpdate.ref;

    return newWell;
  });
};

const metadataReducer = (prevMetadata: Metadata, metadataUpdate: Partial<Metadata>): Metadata => {
  const newMetadata = { ...prevMetadata, ...metadataUpdate };
  localStorage.setItem('metadata', JSON.stringify(newMetadata));
  return newMetadata;
};

const useStyles = makeStyles({
  leftSideGridItems: {
    padding: '16px',
    '@media print': {
      padding: '20px 0'
    }
  },
  root: {
    height: '100vh',
    '@media print': {
      height: 'auto'
    }
  }
});
