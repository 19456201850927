import React from 'react';
import { Feature, FeatureToggle } from './FeatureToggles';
import { Box, IconButton, Tooltip, Button, Typography } from '@material-ui/core';
import { ConditionalWrapper } from './ConditionalWrapper';

interface Props {
  feature?: Feature;
  action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tooltip?: string;
  text?: string;
  light?: boolean;
  disabled?: boolean;
  dataCy?: string;
  hidden?: boolean;
}

export const MyButton: React.FC<Props> = props => {
  const { feature, children, action, tooltip, light, disabled, hidden, text } = props;
  const style = light ? { color: 'lightgrey' } : { color: '#555555' };

  if (text == null && tooltip == null) console.warn('Icon buttons should have a tooltip');
  return (
    <Box displayPrint="none" display={hidden ? 'none' : 'block'}>
      <FeatureToggle feature={feature}>
        <ConditionalWrapper
          condition={Boolean(tooltip)}
          wrapper={(wrappedChildren: any) => (
            <Tooltip title={tooltip}>
              <div>{wrappedChildren}</div>
            </Tooltip>
          )}>
          {!text ? (
            <IconButton onClick={action} style={style} disabled={disabled}>
              {children}
            </IconButton>
          ) : (
            <Button onClick={action} style={style} disabled={disabled} data-cy={props.dataCy ? props.dataCy : ''}>
              {children}
              <Typography style={{ padding: '6px', fontSize: '11px' }} variant="body2">
                {text}
              </Typography>
            </Button>
          )}
        </ConditionalWrapper>
      </FeatureToggle>
    </Box>
  );
};
