import React from 'react';

export type Feature = 'Print' | 'PlateToClipboard' | 'Plate384';

export const FeatureToggleContext = React.createContext([] as Feature[]);

interface Props {
  feature: Feature | undefined;
  children: React.ReactNode;
}

export const FeatureToggle: React.FC<Props> = ({ feature, children }) => (
  <FeatureToggleContext.Consumer>
    {features => (feature == null || features.includes(feature) ? children : null)}
  </FeatureToggleContext.Consumer>
);
