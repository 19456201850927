import { makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast';
import chroma from 'chroma-js';

import { ConditionalFormatting, getWellColour } from './ColourMapper';
import { Well } from './Well';

interface Props extends TSelectableItemProps {
  x: number;
  y: number;
  well: Well;
  conditionalFormatting: ConditionalFormatting;
  setHoverWell: (wellId: string) => void;
  highlight: boolean;
  fontSize: string;
}

const WellView: React.FC<Props> = props => {
  const { x, y, selectableRef, isSelected, fontSize } = props;
  const colourMapper = getWellColour(props.conditionalFormatting);
  const wellColour = colourMapper(props.well);
  const { wellCircle } = useStyles();

  // create a border colour that is a bit darker than the well colour (to enhance the contrast)
  const borderColour = chroma(wellColour).darken();
  const wellView = (
    <div
      ref={selectableRef}
      onMouseOver={() => props.setHoverWell(props.well.wellId)}
      style={{
        gridRow: y,
        gridColumn: x,
        background: isSelected || props.highlight ? 'rgba(100, 181, 246, 0.4)' : '',
        padding: '6%'
      }}>
      <div
        className={wellCircle}
        style={{
          border: isSelected ? '1px solid black' : `1px solid ${borderColour}`,
          background: wellColour,
          fontSize
        }}>
        <svg width="100%" viewBox="-500 -650 1000 1000">
          <text fontSize="400" fill="black" style={{ textAnchor: 'middle' }}>
            {props.well.ref}
          </text>
        </svg>
      </div>
    </div>
  );

  if (isMobile) return wellView;

  return (
    <HtmlTooltip
      enterDelay={200}
      title={
        <React.Fragment>
          <Typography color="inherit">{`${props.well.wellId}: ${props.well.name}`}</Typography>
          {props.well.notes}
        </React.Fragment>
      }>
      {wellView}
    </HtmlTooltip>
  );
};

export const SelectableWellView = createSelectable(
  memo(WellView, (prev, next) => {
    return (
      prev.well === next.well &&
      prev.conditionalFormatting === next.conditionalFormatting &&
      prev.highlight === next.highlight &&
      prev.isSelected === next.isSelected
    );
  })
);

const useStyles = makeStyles(theme => ({
  wellCircle: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    border: '1px solid #dadde9'
  }
}))(Tooltip);
