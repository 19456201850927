import React from 'react';
import {
  Select,
  Checkbox,
  MenuItem,
  TableBody,
  makeStyles,
  Typography,
  Grid,
  Paper
} from '@material-ui/core';
import { Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { Field } from './ImportDialog';
import { JoyRideSteps } from './JoyRide';
import startCase from 'lodash.startcase';
import { parseWellId } from './Well';

interface Props {
  data: string[][];
  getWellId: (index: number) => string;
  hasHeader: boolean;
  setFields: React.Dispatch<Partial<Field>>;
  fields: Field[];
}

export const columns = ['wellId', 'type', 'name', 'ref', 'value', 'notes'];

export const ImportTable: React.FC<Props> = props => {
  const { data, getWellId, hasHeader, fields, setFields } = props;

  const hideAssignedWells = fields.some(a => a.targetColumn === 'wellId' && a.selected);
  const assignedWellFieldName = !hideAssignedWells
    ? undefined
    : fields.filter(a => a.targetColumn === 'wellId' && a.selected)[0];

  const { table, assignedWellCell } = styles();
  return (
    <>
      <Typography
        variant="caption"
        style={{ position: 'relative', left: '10px', height: 0, top: '10px' }}>
        Source
      </Typography>
      <Typography
        variant="caption"
        style={{ position: 'relative', left: '10px', height: 0, top: '55px' }}>
        Destination
      </Typography>
      <Paper elevation={3} style={{ overflow: 'auto', marginLeft: '90px' }}>
        <Table size="small">
          <TableHead id={JoyRideSteps.sampleTableEdit} className={table} data-cy="import-header">
            <TableRow style={{ height: '36px' }}>
              <TableCell></TableCell>

              {fields.map(a => (
                <TableCell key={a.index}>
                  <Typography variant="caption">
                    {hasHeader ? `${data[0][a.index]}` : null}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={`${assignedWellCell}`}>
                <Grid container direction="column">
                  <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                    Assigned
                  </Typography>
                  <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                    Well ID
                  </Typography>
                </Grid>
              </TableCell>
              {fields.map(a => (
                <TableCell key={a.index}>
                  <Select
                    value={a.targetColumn}
                    style={{ minWidth: '100px' }}
                    onChange={e =>
                      setFields({
                        index: a.index,
                        targetColumn: e.target.value as string,
                        selected: true
                      })
                    }
                    margin="dense">
                    {columns.map(b => (
                      <MenuItem key={b} value={b}>
                        {startCase(b)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Checkbox
                    checked={a.selected}
                    disabled={!Boolean(a.targetColumn)}
                    onChange={c => setFields({ index: a.index, selected: c.target.checked })}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((a, i) => i !== 0 || !hasHeader)
              .map((row, i) => (
                <TableRow key={i}>
                  <TableCell className={`${assignedWellCell}`}>
                    <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                      {hideAssignedWells
                        ? parseWellId(row[assignedWellFieldName!.index])
                        : getWellId(i)}
                    </Typography>
                  </TableCell>
                  {row.map((key, j) => (
                    <TableCell key={j}>{key}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

const styles = makeStyles(theme => ({
  table: {
    background: '#fafafa'
  },
  assignedWellIds: {
    background: '#fafafa'
  },
  assignedWellCell: {
    borderRight: '1px solid lightgrey'
  }
}));
