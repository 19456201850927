import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SelectableGroup } from 'react-selectable-fast';
import { Holdable } from 'react-touch';

import { AspectRatio } from './AspectRatio';
import { ConditionalFormatting } from './ColourMapper';
import { getWellLetter, Well } from './Well';
import { SelectableWellView as WellView } from './WellView';
import { JoyRideSteps } from './JoyRide';

interface PlateViewProps {
  wells: Well[];
  handleSelectionFinish: any;
  clearSelectionRef: any;
  conditionalFormatting: ConditionalFormatting;
  setHoverWell: (wellId: string) => void;
  hoverWell: string | undefined;
}

export const PlateView: React.FC<PlateViewProps> = React.memo(props => {
  const { wells, handleSelectionFinish } = props;
  const rows = wells.filter(a => a.x === 0).length;
  const columns = wells.filter(a => a.y === 0).length;
  const classes = useStyles(columns, rows)();
  const { gridContainer, paperPlate, wellNumbering } = classes;
  const [inSelectMode, setInSelectMode] = useState(false);

  const enterSelectMode = () => {
    setInSelectMode(true);
  };

  const leaveSelectMode = () => {
    // setInSelectMode(false);
  };

  return (
    <SelectableGroup
      clickClassName="tick"
      tolerance={0}
      ref={props.clearSelectionRef}
      deselectOnEsc
      onSelectionFinish={handleSelectionFinish}
      disabled={isMobile && !inSelectMode}>
      <Holdable onHoldComplete={enterSelectMode}>
        <Paper
          id={JoyRideSteps.plateSelection}
          className={paperPlate}
          onTouchEnd={leaveSelectMode}
          elevation={2}
          onMouseLeave={() => props.setHoverWell('')}>
          <AspectRatio ratio={(columns + 2) / (rows + 2.0)}>
            <div className={gridContainer} id="plate">
              {getRowColumnNumbering(wells, wellNumbering)}
              {wells.map(a => (
                <WellView
                  x={a.x + 2}
                  y={a.y + 2}
                  well={a}
                  key={a.wellId}
                  highlight={a.wellId === props.hoverWell}
                  setHoverWell={props.setHoverWell}
                  conditionalFormatting={props.conditionalFormatting}
                />
              ))}
            </div>
          </AspectRatio>
        </Paper>
      </Holdable>
    </SelectableGroup>
  );
});

const getRowColumnNumbering = (wells: Well[], className: string) => {
  const rows = wells
    .filter(a => a.x === 0)
    .map(a => ({
      text: getWellLetter(a.y),
      gridColumn: 1,
      gridRow: a.y + 2
    }));

  const columns = wells
    .filter(a => a.y === 0)
    .map(a => ({
      text: a.x + 1,
      gridColumn: a.x + 2,
      gridRow: 1
    }));

  return [...rows, ...columns].map(({ text, gridColumn, gridRow }) => (
    <div key={`row${text}`} style={{ gridColumn, gridRow, display: 'flex' }}>
      <svg width="100%" viewBox="-500 -700 1000 800">
        <text fontSize="400" className={className} children={text} />
      </svg>
    </div>
  ));
};

const useStyles = (columns: number, rows: number) =>
  makeStyles(theme => ({
    paperPlate: {
      width: '100%',
      height: 'calc(width * 1)',
      '@media print': {
        boxShadow: 'none',
        border: '1px solid grey'
      }
    },
    gridContainer: {
      position: 'relative',
      display: 'grid',
      height: '100%',
      width: '100%',
      gridTemplateColumns: `repeat(${columns + 2}, 1fr)`,
      gridTemplateRows: `repeat(${rows + 2}, 1fr)`
    },
    wellNumbering: {
      fill: theme.palette.text.hint,
      textAnchor: 'middle',
      fontWeight: 800
    }
  }));
