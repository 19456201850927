import { convertWellToWellId, Well } from './Well';

export interface PlateType {
  width: number;
  height: number;
  name: string;
}

export const plate96: PlateType = {
  width: 12,
  height: 8,
  name: '96 Well'
};

export const plate384: PlateType = {
  width: 24,
  height: 16,
  name: '384 Well'
};

export interface PlateMetadata {
  title: string;
  username: string;
  date: Date;
  notes: string;
}

export const generateWellSeed = (plateType: PlateType) => {
  const wells: Well[] = [];
  for (let x = 0; x < plateType.width; x++) {
    for (let y = 0; y < plateType.height; y++) {
      wells.push({
        x,
        y,
        wellId: convertWellToWellId(x, y),
        ref: '',
        value: '',
        type: '',
        notes: '',
        name: ''
      });
    }
  }

  return wells;
};

export const sortLeftToRight = (a: Well, b: Well) => {
  if (a.y === b.y && a.x === b.x) return 0;

  if (a.y < b.y || (a.y === b.y && a.x < b.x)) return -1;

  return 1;
};

export const sortTopToBottom = (a: Well, b: Well) => {
  if (a.y === b.y && a.x === b.x) return 0;

  if (a.x < b.x || (a.x === b.x && a.y < b.y)) return -1;

  return 1;
};

export const getWellIdByWellCount = (
  wellCount: number,
  plateType: PlateType,
  leftToRight: boolean
) => {
  if (leftToRight) {
    const column = wellCount % plateType.width;
    const row = Math.floor(wellCount / plateType.width);
    return convertWellToWellId(column, row);
  } else {
    const column = Math.floor(wellCount / plateType.height);
    const row = wellCount % plateType.height;
    return convertWellToWellId(column, row);
  }
};
