import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { useReducer, useState } from 'react';

import { getWellIdByWellCount, PlateType } from './PlateType';
import { Well } from './Well';
import { ImportTable, columns } from './ImportTable';

interface Props {
  show: boolean;
  close: () => void;
  updateSelection: (values: Partial<Well>[]) => void;
  plateType: PlateType;
  sourceData: any[][];
}

export interface Field {
  index: number;
  selected: boolean;
  targetColumn: string;
}

export const ImportDialog: React.FC<Props> = props => {
  const { plateType, sourceData } = props;

  const [leftToRight, setLeftToRight] = useState('');
  const [hasHeader, setHasHeader] = useState(true);
  const [fields, setFields] = useReducer(
    (prevState: Field[], updateValues: Partial<Field>) => {
      return prevState.map(a => ({
        ...a,
        ...(updateValues.index === a.index ? updateValues : {})
      }));
    },
    sourceData[0].map((a, i) => ({
      index: i,
      selected: columns.includes(a),
      targetColumn: columns.includes(a) ? a : ''
    }))
  );

  const submitOnEnter = (ev: any) => {
    if (ev.key === 'Enter') {
      onSubmit();
      ev.preventDefault();
    }
  };

  const getWellId = (wellIndex: number) =>
    leftToRight ? getWellIdByWellCount(wellIndex, plateType, leftToRight === 'rowFirst') : '-';

  const onSubmit = () => {
    const updateData = sourceData
      .filter((_, i) => !(i === 0 && hasHeader))
      .map((a, i) => {
        // note: wellId is set here, but if it is present and selected on the parseResult object, it will be overridden below
        const updateVals = { wellId: getWellId(i) } as any;

        fields
          .filter(f => f.selected)
          .forEach(element => {
            updateVals[element.targetColumn] = a[element.index];
          });

        return updateVals;
      });

    props.updateSelection(updateData);
    props.close();
  };

  const hasWellIdColumnSelected = fields.some(a => a.targetColumn === 'wellId' && a.selected);
  const canSubmit = hasWellIdColumnSelected || Boolean(leftToRight);

  // select import from clipboard or import from file
  // column ordering of input data
  return (
    <Dialog
      onClose={props.close}
      maxWidth="xl"
      open={props.show}
      onKeyPress={submitOnEnter}
      disableBackdropClick>
      <DialogTitle id="confirmation-dialog-title">Import</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={2} style={{ minWidth: '300px' }}>
          <Grid item>
            <FormControl component="fieldset" disabled={hasWellIdColumnSelected}>
              <FormLabel component="legend">Assigned Well Mapping</FormLabel>
              <RadioGroup value={leftToRight} onChange={a => setLeftToRight(a.target.value)}>
                <FormControlLabel
                  value="columnFirst"
                  control={<Radio />}
                  label="Column First (A1, B1, C1)"
                />
                <FormControlLabel
                  value="rowFirst"
                  control={<Radio />}
                  label="Row First (A1, A2, A3)"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox checked={hasHeader} onChange={a => setHasHeader(a.target.checked)} />
              }
              label="Has Headers"
            />
          </Grid>

          <ImportTable
            getWellId={getWellId}
            data={sourceData}
            hasHeader={hasHeader}
            setFields={setFields}
            fields={fields}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" disabled={!canSubmit} data-cy="import-confirm">
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};
