import { Well } from './Well';
import chroma from 'chroma-js';

export interface ConditionalFormatting {
  valueMin: number;
  valueMax: number;
}

export const scale = chroma
  .scale(['#D1EEEA', '#A8DBD9', '#85C4C9', '#68ABB8', '#4F90A6', '#3B738F', '#2A5674'])
  .mode('lab');

const sampleColourScale = chroma.brewer.Blues;
const referenceColourScale = chroma.brewer.BuGn;

export const getWellColour = (cf: ConditionalFormatting) => {
  const { valueMax, valueMin } = cf;
  const sampleScale = chroma
    .scale(sampleColourScale)
    .domain([0, valueMax * 1.4])
    .mode('lab');

  const referenceScale = chroma
    .scale(referenceColourScale)
    .domain([0, valueMax * 1.8])
    .mode('lab');

  return (well: Well) => {
    const v = well.value === '' || isNaN(Number(well.value)) ? valueMax : Number(well.value);

    switch (well.type) {
      case 'Sample':
        return sampleScale(v).hex();
      case 'Reference':
        return referenceScale(v).hex();
      case 'Control':
        return `rgb(244, 202, 100)`;
      case 'Blank':
        return `rgb(136, 149, 167)`;
      default:
        return 'lightgrey';
    }
  };
};
