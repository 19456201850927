import LuxonUtils from '@date-io/luxon';
import { TextField, Grid, makeStyles, Paper } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

export interface Metadata {
  assayName: string | undefined;
  value: string | undefined;
  plateId: string | undefined;
  username: string | undefined;
  date: string | undefined;
  notes: string | undefined;
}

interface Props {
  setMetadata: React.Dispatch<Partial<Metadata>>;
  metadata: any;
}

export const TableMetadata: React.FC<Props> = ({ setMetadata, metadata }) => {
  const { input, root, metadataItems } = styles();
  const textFieldProps = {
    margin: 'dense' as 'dense',
    onPaste: (a: any) => a.stopPropagation(),
    // variant: 'standard' as 'standard',
    InputProps: { className: input },
    fullWidth: true
  };

  return (
    <Paper className={root} elevation={0}>
      <Grid container direction="row">
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <TextField
              id="assay-name"
              label="Assay Name"
              value={metadata.assayName}
              onChange={a => setMetadata({ assayName: a.target.value })}
              {...textFieldProps}
            />
          </Grid>
          <Grid item xs={6} className={metadataItems}>
            <TextField
              id="plate-identifier"
              label="Plate Identifier"
              value={metadata.plateId}
              onChange={a => setMetadata({ plateId: a.target.value })}
              {...textFieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="user"
              label="User"
              value={metadata.username}
              onChange={a => setMetadata({ username: a.target.value })}
              {...textFieldProps}
            />
          </Grid>
          <Grid item xs={6} className={metadataItems}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <DatePicker
                {...textFieldProps}
                id="date-picker-dialog"
                label="Date"
                format="yyyy-MM-dd"
                value={metadata.date}
                onChange={date => setMetadata({ date })}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="notes"
            label="Notes"
            value={metadata.notes}
            multiline
            rowsMax={3}
            onChange={a => setMetadata({ notes: a.target.value })}
            {...textFieldProps}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = makeStyles(theme => ({
  input: {
    '&::before': {
      color: 'red',
      border: `1px 0 0 0 solid`,
      borderColor: theme.palette.divider
    }
  },
  root: {
    background: 'transparent'
  },
  metadataItems: {
    paddingLeft: '16px'
  }
}));
