import { InputBase, TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import chroma from 'chroma-js';

import { ConditionalFormatting, getWellColour } from './ColourMapper';
import { Well } from './Well';

interface Props {
  well: Well;
  conditionalFormatting: ConditionalFormatting;
  setWellField: (wellId: string, type: string, value: string) => void;
  setHoverWell: (wellId: string) => void;
  highlight: boolean;
}

const TableRecord: React.FC<Props> = ({
  well,
  setWellField,
  conditionalFormatting,
  highlight,
  setHoverWell
}) => {
  const colourMapper = getWellColour(conditionalFormatting);

  const [edit, setEdit] = useState(false);

  const wellColour = colourMapper(well);

  // create a border colour that is a bit darker than the well colour (to enhance the contrast)
  const borderColour = chroma(wellColour).darken();

  return (
    <TableRow
      key={well.wellId}
      style={{ background: highlight ? 'rgba(100, 181, 246, 0.4)' : '' }}
      onClick={() => setEdit(true)}
      onMouseOver={() => setHoverWell(well.wellId)}>
      <TableCell>{well.wellId}</TableCell>
      <TableCell style={{ padding: 0 }}>
        <div
          style={{
            borderRadius: '50%',
            border: `0.5px solid ${borderColour}`,
            width: '13px',
            height: '13px',
            background: wellColour
          }}
        />
      </TableCell>
      {cell(well.type, (v: string) => setWellField(well.wellId, 'type', v), edit)}
      {cell(well.ref, (v: string) => setWellField(well.wellId, 'ref', v), edit)}
      {cell(well.name, (v: string) => setWellField(well.wellId, 'name', v), edit)}
      {cell(well.value, (v: string) => setWellField(well.wellId, 'value', v), edit)}
      {cell(well.notes, (v: string) => setWellField(well.wellId, 'notes', v), edit)}
    </TableRow>
  );
};

export default React.memo(TableRecord);

const cell = (
  value: string,
  setWellField: (value: string) => void,
  edit: boolean,
  width?: number
) => {
  return (
    <TableCell style={{ padding: 0 }}>
      {edit ? (
        <InputBase
          type="text"
          name="fname"
          value={value}
          multiline
          onChange={a => setWellField(a.target.value)}
          onPaste={a => a.stopPropagation()}
          style={{
            borderWidth: 0,
            padding: '0px',
            width: '100%',
            fontSize: '14px',
            lineHeight: '1.43'
          }}
        />
      ) : (
        value
      )}
    </TableCell>
  );
};
