import { AppBar, Toolbar, Box, Tooltip, IconButton } from '@material-ui/core';
import Brightness4 from '@material-ui/icons/Brightness4';
import FeatureToggleIcon from '@material-ui/icons/Whatshot';
import React from 'react';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpIcon from '@material-ui/icons/Help';

import logo from './logo-dark.svg';
import { MyButton } from './MyButton';
import { JoyRideSteps, JoyRide } from './JoyRide';
import { OpenTextFile } from './OpenTextFile';

import NoteAdd from '@material-ui/icons/NoteAdd';
import PrintIcon from '@material-ui/icons/Print';
import MenuIcon from '@material-ui/icons/Menu';
import Save from '@material-ui/icons/Save';
import Open from '@material-ui/icons/OpenInBrowser';

import { plate384, plate96, PlateType } from './PlateType';
import Export from 'mdi-material-ui/Export';
import Import from 'mdi-material-ui/Import';
import { ExportMenu } from './ExportMenu';
import { ImportMenu } from './ImportMenu';
import { DrawerMenu } from './DrawerMenu';
import { useMediaQuery } from '@material-ui/core';
import { NewPlateButton } from './NewPlateButton';

interface Props {
  toggleFeatures: () => void;
  toggleDarkMode: () => void;

  save: () => void;
  load: (content: string) => void;
  newPlate: (plateType: PlateType) => void;
  print: () => void;
  copyToClipboard: () => void;
  exportToCsv: () => void;
  importFromClipboard: () => void;
  importFromFile: () => void;
}

export const MyAppBar: React.FC<Props> = props => {
  const { save, load, toggleFeatures, toggleDarkMode } = props;
  const new384 = () => props.newPlate(plate384);
  const new96 = () => props.newPlate(plate96);

  const isWideLayout = useMediaQuery('(min-width:840px)');
  const commonProps = { light: true, hidden: !isWideLayout };

  return (
    <AppBar
      position="static"
      style={{ background: '#424242', height: '80px', position: 'relative' }}
      elevation={3}>
      <Toolbar>
        {isWideLayout ? null : (
          <DrawerMenu {...props}>
            {openDrawer => (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={openDrawer}
                data-cy="open-drawer"
                style={{ paddingRight: '10px' }}>
                <MenuIcon />
              </IconButton>
            )}
          </DrawerMenu>
        )}
        <a
          href="https://firefinch.io/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ paddingRight: '20px' }}>
          <img
            src={logo}
            style={{ height: '100px', marginTop: '-10px', marginBottom: '-10px' }}
            alt=""
          />
        </a>

        <MyButton text="Save" action={save} children={<Save />} {...commonProps} />
        <OpenTextFile loadTextContent={load} dataCy="file-open">
          {action => <MyButton text="Open" action={action} children={<Open />} {...commonProps} />}
        </OpenTextFile>

        <NewPlateButton new96={new96} new384={new384}>
          {openMenu => (
            <MyButton text="New" action={openMenu} children={<NoteAdd />} {...commonProps} />
          )}
        </NewPlateButton>

        <ImportMenu
          importFromClipboard={props.importFromClipboard}
          importFromFile={props.importFromFile}>
          {openImportMenu => (
            <div id={JoyRideSteps.import}>
              <MyButton
                dataCy="import-button"
                text="Import"
                action={openImportMenu}
                children={<Import />}
                {...commonProps}
              />
            </div>
          )}
        </ImportMenu>

        <ExportMenu copyToClipboard={props.copyToClipboard} exportToCsv={props.exportToCsv}>
          {openDialog => (
            <div id={JoyRideSteps.export}>
              <MyButton text="Export" action={openDialog} children={<Export />} {...commonProps} />
            </div>
          )}
        </ExportMenu>

        <MyButton text="Print" action={props.print} children={<PrintIcon />} {...commonProps} />

        <div style={{ flexGrow: 1 }} />

        <MyButton tooltip="Night Mode" action={toggleDarkMode} children={<Brightness4 />} light />
        <MyButton tooltip="Enable unstable features" action={toggleFeatures} {...commonProps}>
          <FeatureToggleIcon />
        </MyButton>
        <JoyRide>
          {restartTutorial => (
            <MyButton tooltip="Replay tutorial" action={restartTutorial} {...commonProps}>
              <HelpIcon />
            </MyButton>
          )}
        </JoyRide>

        <Box
          displayPrint="none"
          display={isWideLayout ? 'block' : 'none'}
          id={JoyRideSteps.sendFeedback}>
          <Tooltip title="Send Feedback">
            <div>
              <IconButton
                href={getFeedbackHref()}
                style={{ color: 'lightgrey' }}
                hidden={commonProps.hidden}>
                <FeedbackIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const getFeedbackHref = () => {
  const body = [``];

  return `mailto:plateau@firefinch.io;?subject=Feedback&body=${body.join('%0D%0A')}`;
};
