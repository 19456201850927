import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { Well } from './Well';

interface Props {
  wells: Well[];
  updateSelection: (values: Partial<Well>[]) => void;
  children: (openDialog: () => void) => JSX.Element;
}

const getIfAllMatch = <T extends {}>(values: T[], fieldName: keyof T) => {
  if (values == null || values.length === 0) return undefined;
  return values.every(a => a[fieldName] && a[fieldName] === values[0][fieldName])
    ? String(values[0][fieldName])
    : undefined;
};

export const SelectionEditor: React.FC<Props> = props => {
  const [dialogShowing, setDialogShowing] = useState(false);
  const closeDialog = () => setDialogShowing(false);
  const openDialog = () => setDialogShowing(true);
  const [type, setType] = useState('' as undefined | string);
  const [name, setName] = useState('' as undefined | string);
  const [notes, setNotes] = useState('' as undefined | string);
  const [value, setValue] = useState('' as undefined | string);
  const [ref, setRef] = useState('' as undefined | string);

  useEffect(() => {
    setType(getIfAllMatch(props.wells, 'type'));
    setName(getIfAllMatch(props.wells, 'name'));
    setNotes(getIfAllMatch(props.wells, 'notes'));
    setValue(getIfAllMatch(props.wells, 'value'));
    setRef(getIfAllMatch(props.wells, 'ref'));
  }, [props.wells]);

  const [startingValue, setStartingValue] = useState(1);
  const [replicates, setReplicates] = useState(1);

  if (!dialogShowing) return props.children(openDialog);

  const onSubmit = () => {
    let counter = 0;
    const newWells = props.wells.map(a => {
      let newRef = ref;
      const refNumber = Math.floor(counter / replicates) + startingValue;
      if (type != null) {
        if (type === 'Sample') {
          newRef = `S${refNumber}`;
        } else if (type === 'Reference') {
          newRef = `R${refNumber}`;
        } else {
          newRef = '';
        }
      }

      counter++;
      return { ...a, type, name, notes, value, ref: newRef };
    });

    props.updateSelection(newWells);
    closeDialog();
  };

  const submitOnEnter = (ev: any) => {
    if (ev.key === 'Enter') {
      onSubmit();
      ev.preventDefault();
    }
  };
  const helperText = (fieldName: string | undefined) =>
    fieldName === undefined ? 'Selected wells contain different text' : '';

  const hasReferenceNumbering = type === 'Sample' || type === 'Reference';
  return (
    <Dialog
      onClose={closeDialog}
      maxWidth={hasReferenceNumbering ? 'sm' : 'xs'}
      open={dialogShowing}
      onKeyPress={submitOnEnter}
      disableBackdropClick>
      <DialogTitle id="confirmation-dialog-title">Well Update</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={hasReferenceNumbering ? 7 : 12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Type</InputLabel>
              <Select value={type} onChange={a => setType(a.target.value as string)}>
                <MenuItem value="Sample">Sample</MenuItem>
                <MenuItem value="Reference">Reference</MenuItem>
                <MenuItem value="Control">Control</MenuItem>
                <MenuItem value="Blank">Blank</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              variant="outlined"
              value={name}
              onChange={a => setName(a.target.value)}
              label="Name"
              margin="normal"
              helperText={helperText(name)}
            />
            <TextField
              fullWidth
              variant="outlined"
              value={notes}
              onChange={a => setNotes(a.target.value)}
              label="Notes"
              margin="normal"
              multiline
              helperText={helperText(notes)}
            />
            <TextField
              fullWidth
              variant="outlined"
              value={value}
              onChange={a => setValue(a.target.value)}
              label="Value"
              helperText={helperText(value)}
              margin="normal"
            />
          </Grid>
          {hasReferenceNumbering ? (
            <Grid item xs={hasReferenceNumbering ? 5 : 12}>
              <Typography>Reference Numbering:</Typography>
              <TextField
                type="number"
                fullWidth
                variant="outlined"
                value={startingValue}
                onChange={a => setStartingValue(Number(a.target.value))}
                label="Starting Reference Number"
                margin="normal"
              />
              <TextField
                type="number"
                fullWidth
                variant="outlined"
                value={replicates}
                onChange={a => setReplicates(Number(a.target.value))}
                label="Number of Replicates"
                margin="normal"
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
