import {
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';

import { ConditionalFormatting } from './ColourMapper';
import { sortLeftToRight, sortTopToBottom } from './PlateType';
import TableRecord from './TableRecord';
import { Well } from './Well';

const useStyles = makeStyles({
  root: {
    overflow: 'auto',
    '@media print': {
      overflow: 'hidden',
      boxShadow: 'none'
    }
  },
  table: {
    minWidth: '700px'
  },
  menuIcon: {
    paddingRight: '14px',
    height: '18px'
  },
  standardColumn: {
    padding: 0
  },
  columnWithMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export const TableView: React.FC<TableViewProps> = props => {
  const { root, table, standardColumn, menuIcon, columnWithMenu } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [leftToRightOrder, setLeftToRightOrder] = useState(false);
  const toggleWellOrder = () => {
    handleClose();
    setLeftToRightOrder(!leftToRightOrder);
  };
  const sort = leftToRightOrder ? sortLeftToRight : sortTopToBottom;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = (
    <div>
      <IconButton onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={toggleWellOrder}>
          <FormatAlignJustifyIcon color="action" className={menuIcon} />
          <Typography>Order By Row/Column</Typography>
        </MenuItem>
      </Menu>
    </div>
  );

  const isWideLayout = useMediaQuery('(min-width:960px)');
  return (
    <Paper
      className={root}
      elevation={2}
      style={{ height: isWideLayout ? '100%' : 'auto', position: 'relative', zIndex: 2 }}
      onMouseLeave={() => props.setHoverWell('')}>
      <Table className={table} size="small" stickyHeader>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '2%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '5%' }} />
          <col style={{ width: '28%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '40%' }} />
        </colgroup>
        <TableHead style={{ zIndex: 10 }}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell className={standardColumn}></TableCell>
            <TableCell className={standardColumn}>Type</TableCell>
            <TableCell className={standardColumn}>Ref</TableCell>
            <TableCell className={standardColumn}>Name</TableCell>
            <TableCell className={standardColumn}>Value</TableCell>
            <TableCell className={standardColumn}>
              <div className={columnWithMenu}>Notes{menu}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-cy="well-table">
          {props.wells.sort(sort).map(well => (
            <TableRecord
              well={well}
              key={well.wellId}
              conditionalFormatting={props.conditionalFormatting}
              setWellField={props.setWellField}
              highlight={props.hoverWell === well.wellId}
              setHoverWell={props.setHoverWell}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

interface TableViewProps {
  wells: Well[];
  conditionalFormatting: ConditionalFormatting;
  setWellField: (wellId: string, type: string, value: string) => void;
  hoverWell: string | undefined;
  setHoverWell: (wellId: string) => void;
}
