import { parse } from 'papaparse';
import React, { useState } from 'react';

import { ImportDialog } from './ImportDialog';
import { OpenTextFile } from './OpenTextFile';
import { PlateType } from './PlateType';
import { Well } from './Well';
import { PasteImportDialog } from './PasteImportDialog';

interface Props {
  updateSelection: (values: Partial<Well>[]) => void;
  plateType: PlateType;
  importType: 'clipboard' | 'file';
  children: (open: () => void) => React.ReactNode;
}

export const ImportDialogContainer: React.FC<Props> = props => {
  const [sourceData, setSourceData] = useState(undefined as any[] | undefined);
  const [showCopy, setShowCopy] = useState(false);
  const plateType = props.plateType;

  const parseCsv = (csv: string) => {
    const sourceData = parse(csv, { skipEmptyLines: true }).data;
  
    if (sourceData.length === 0) {
      alert('Invalid data in clipboard');
    } else {
      setSourceData(sourceData);
    }
  };


  const importFromPasteModal = (sourceData: any[]) => {
    setSourceData(sourceData)
    setShowCopy(false);
  }

  const importFromClipboard = async () => {
    const readData = async () => {
      const input = await navigator.clipboard.readText();
      parseCsv(input);
    };

    if(navigator.clipboard?.readText as () => Promise<string>){
      return await readData()
    } else {
      setShowCopy(true);
    }
  };

  return (
    <>
      {props.importType === 'clipboard' ? (
        props.children(importFromClipboard)
      ) : (
        <OpenTextFile loadTextContent={parseCsv} dataCy="file-import">
          {uploadCsv => props.children(uploadCsv)}
        </OpenTextFile>
      )}
      {sourceData ? (
        <ImportDialog
          show={Boolean(sourceData)}
          close={() => setSourceData(undefined)}
          updateSelection={props.updateSelection}
          plateType={plateType}
          sourceData={sourceData}
        />
      ) : null}
      <PasteImportDialog show={showCopy} onSubmit={importFromPasteModal} onClose={() => setShowCopy(false)} />
    </>
  );
};
