import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';

interface Props {
  children: (
    openMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => React.ReactNode;
  importFromClipboard: () => void;
  importFromFile: () => void;
}

export const ImportMenu: React.FC<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const importClick = (importFunc: () => void) => () => {
    importFunc();
    setAnchorEl(null);
  };

  return (
    <>
      {props.children(handleClick)}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem data-cy="import-clipboard" onClick={importClick(props.importFromClipboard)}>
          Import From Clipboard
        </MenuItem>
        <MenuItem data-cy="import-file" onClick={importClick(props.importFromFile)}>
          Import From File
        </MenuItem>
      </Menu>
    </>
  );
};
